import axios from 'axios';
import Swal from 'sweetalert2';
import QueryString from 'qs';
import L from 'leaflet';

/**
 * Init
 */
const documentReady = function () {
    new ContactLogicHandler();
};

if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}

/**
 * [...]
 */
class ContactLogicHandler {
    constructor() {
        // Make section map
        this.makeMap();

        this.form = document.getElementById('js-contact-form');

        if (!this.form) {
            throw new Error(`Form with given selector not found.`);
        }

        this.attachEventListeners();
    }

    /**
     * Event listeners.
     */
    attachEventListeners() {

        // Form send
        this.form.addEventListener('submit', (event) => this.sendForm(event));
    }

    /**
     * [...]
     *
     * @returns 
     */
    makeMap() {
        const contactMap = document.getElementById('js-contact-map');

        if (contactMap) {
            const rect = contactMap.getBoundingClientRect();

            let flag = false;

            window.addEventListener('scroll', () => {
                if (rect.top <= window.scrollY + window.outerHeight) {
                    if (flag === false) {
                        const map = L.map('js-contact-map').setView([52.406476, 16.924079], 16);

                        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                        }).addTo(map);

                        L.marker([52.406476, 16.924079], {
                            icon: L.icon({
                                iconUrl: `${THEME_URL}/dist/img/location-dot.svg`,
                                iconSize: [36, 36],
                                iconAnchor: [18, 36],
                            }),
                        }).addTo(map);
                    }

                    flag = true;
                }
            });
        }
    }

    /**
     * [...]
     *
     * @param {*} event 
     */
    sendForm(event) {
        event.preventDefault();

        const formData = new FormData(this.form);
        const dataObject = {};
        formData.forEach((value, key) => {
            dataObject[key] = value;
        });

        const honeypot = document.getElementById('js-hnypot');
        const contactSendButton = document.getElementById('js-contact-send-button');

        if (honeypot.value.length === 0) {
            contactSendButton.setAttribute('disabled', 'true');
            contactSendButton.textContent = 'Wysyłam';

            axios.post(ajax.url, 
                QueryString.stringify({
                    action: 'send_contact_form',
                    security: ajax.nonce,
                    data: dataObject
                })).then((responseRaw) => {
                const response = responseRaw.data;

                if (responseRaw.data.success === true) {
                    contactSendButton.textContent = 'Wysłano';
                    contactSendButton.classList.add('is-sent');

                    Swal.fire({
                        icon: 'success',
                        title: 'Udało się!',
                        text: 'Wiadomość została wysłana.'
                    });
                } else {
                    contactSendButton.textContent = 'Wyślij';
                    contactSendButton.removeAttribute('disabled');

                    let errorsList = '';

                    response.data.errors.forEach((element) => {
                        errorsList += `<li>${element}</li>`;
                    });

                    Swal.fire({
                        icon: 'warning',
                        title: 'Uwaga',
                        html: `W formularzu wystąpiły następujące błędy:<ul style="text-align: left">${errorsList}</ul>`
                    });
                }
            }).catch(() => {
                contactSendButton.textContent = 'Błąd';
                contactSendButton.classList.add('is-error');

                Swal.fire({
                    icon: 'error',
                    title: 'Błąd',
                    text: 'Podczas wysyłki wiadomości wystąpił nieoczekiwany błąd.'
                });
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Błąd',
                text: 'Prawdopodobna próba wysłania spam-wiadomości!'
            });
        }
    }
}
